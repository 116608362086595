<template >
  <!-- NodeMap Start -->
  <section id="nodemap">
    <div class="review-block" style="padding-top:100px; padding-bottom:100px;">

      <div class="container">
        <h2 class="title"><span> ETHO</span> Protocol Node Network</h2>
        <div class="row margin-bottom">
          <div class="col-lg-4 col-md-6">
            <div class="review-box">
              <p style="font-size: 18px;">Active Uploads Contracts: </p><h1 style="font-size: 28px;"><span class="f-bold">{{ active_contracts }}</span></h1>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="review-box ">
              
              <p style="font-size: 18px;">Available Network Storage: </p><h1 style="font-size: 28px;"><span class="f-bold">{{ available_storage }}</span></h1>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="review-box">
              <p style="font-size: 18px;">Used Network Storage: </p><h1 style="font-size: 28px;"><span class="f-bold">{{ used_storage }}</span></h1>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="container">
        <div class="row">
          <div class="col-12">
    <GChart
      :settings="{ packages: ['geochart'], mapsApiKey: 'AIzaSyAxXYo_SRindYMr7DOK-wXHdIuMh3xQkqU'}"
      type="GeoChart"
      :data="chartData"
      :options="chartOptions"
    />
        </div>
      </div>
    </div>

    <div class="review-block" style="padding-top:100px; padding-bottom:100px;">

      <div class="container">
        <div class="row margin-bottom">
          <div class="col-lg-4 col-md-6">
            <div class="review-box">
              <p style="font-size: 18px;">Active Service Nodes: </p><h1 style="font-size: 28px;"><span class="f-bold">{{ sn_count }}</span></h1>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="review-box">
              <p style="font-size: 18px;">Active Masternodes: </p><h1 style="font-size: 28px;"><span class="f-bold">{{ mn_count }}</span></h1>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="review-box">
              <p style="font-size: 18px;">Active Gateway Nodes: </p><h1 style="font-size: 28px;"><span class="f-bold">{{ gn_count }}</span></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- NodeMap End -->
</template>
<script>
import { GChart } from 'vue-google-charts';
import $ from 'jquery';
import EthoFS from "../services/EthoFS";

export default {
  name: 'node-map',
  components: {
    GChart
  },
  mounted() {
    this.getNodeCoordinates(this);
    this.getNetworkStats(this);
    console.log("EthoFS",this.ethofs)


  },
  methods: {
    getStorageSize(_size) {
      let newsize = parseInt(_size)
      let _decimal = 2
      console.log(_size)
      if (newsize < 1000) {
        return parseInt(_size).toFixed(_decimal) + " B"
      } else if (newsize < 1000000) {
        return (parseInt(_size) / 1000).toFixed(_decimal) + " KB"
      } else if (newsize < 1000000000) {
        return (parseInt(_size) / 1000000).toFixed(_decimal) + " MB"
      }  else if (newsize < 1000000000000) {
        return (parseInt(_size) / 1000000000).toFixed(_decimal) + " GB"
      } else {
        return (parseInt(_size) / 1000000000000).toFixed(_decimal) + " TB"
      }
        
    },
    getNetworkStats(self) {
      $.getJSON('https://api.ethoprotocol.com/api?module=basic&action=network_stats', (data) => {
        self.gn_count = Number(data.active_gatewaynodes);
        self.mn_count = Number(data.active_masternodes);
        self.sn_count = Number(data.active_servicenodes);
        // self.active_contracts = Number(data.activeUploadContracts);
        // self.available_storage = (Number(data.networkStorageAvailable) / 1000000000).toFixed(2);
        self.available_storage = _this.getStorageSize(data.networkStorageAvailable);
        // self.used_storage = (Number(data.totalNetworkStorageUse) / 1000000000).toFixed(2);
      });

      var _this = this;
      this.ethofs.ethofs_storage_contract.methods.contractsTotalSize().call(function (error, result) {
        console.log("result",result)
        // _this.used_storage = (Number(result) / 1000000000).toFixed(2);
        _this.used_storage = _this.getStorageSize(result);
      });

      this.ethofs.ethofs_storage_contract.methods.contractsTotalCount().call(function (error, result) {
        console.log("result",result)
        _this.active_contracts = Number(result);
        // _this.active_contracts = _this.getStorageSize(result);
      });
    },
    getNodeCoordinates(self) {
        $.getJSON('https://api.ethoprotocol.com/api?module=basic&action=node_locations', (data) => {
        let node_strs = [];
        data.forEach(function(node) {
          //var numSwitch = 1;

          //if(Math.floor(Math.random() * 2) == 1) {
          //  numSwitch = -1;
          //}
            let xy = node.x.toFixed(2).toString() + node.y.toFixed(2).toString()
           
            if (node_strs.indexOf(xy) === -1) {
                node_strs.push(xy)
                self.chartData.push([node.x, node.y]);
                //console.log(self.chartData.length)
            }

        })
      });
    }
  },
  data () {
    return {
      gn_count: 0,
      mn_count: 0,
      sn_count: 0,
      active_contracts: 0,
      available_storage: 0,
      used_storage: 0,
      ethofs: EthoFS,
      chartData: [
        ['LATITUDE', 'LONGITUDE'],
      ],
      chartOptions: {
        displayMode: 'markers',
        responsive: true,
        enableRegionInteractivity: false,
        colorAxis: {colors: ['#971b45']},
        defaultColor: '#971b45',
        legend: 'none',
      }
    }
  },
};
</script>