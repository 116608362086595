<template>
<div>
  <!-- Footer Section start -->

  <div class="copyright-section index-footer bg-light">
    <center>
      <a href="https://www.facebook.com/Ether1Project/" target="_blank" class="social-link"> <i class="fa fa-facebook"></i> </a>
      <a href="https://twitter.com/EthoProtocol" target="_blank" class="social-link"> <i class="fa fa-twitter" style="padding:10px;"></i> </a>
      <a href="https://www.instagram.com/ether1official/" target="_blank" class="social-link"> <i class="fa fa-instagram" style="padding:10px;"></i> </a>
      <a href="https://github.com/Ether1Project" target="_blank" class="social-link"> <i class="fa fa-github" style="padding:10px;"></i> </a>
      <a href="https://play.google.com/store/apps/details?id=com.ether1.trust.wallet" target="_blank" class="social-link"> <i class="fa fa-android" style="padding:10px;"></i> </a>
      <a href="https://t.me/Ether_1" target="_blank" class="social-link"> <i class="fa fa-telegram" style="padding:10px;"></i> </a>
      <a href="https://old.reddit.com/r/etho1/" target="_blank" class="social-link"> <i class="fa fa-reddit" style="padding:10px;"></i> </a>
      <a href="https://ethoprotocol.medium.com" target="_blank" class="social-link"> <i class="fa fa-medium" style="padding:10px;"></i> </a>
      <a href="https://www.youtube.com/channel/UC3nvSLrJq3m32v_oVHLwFng/" target="_blank" class="social-link"> <i class="fa fa-youtube" style="padding:10px;"></i> </a>
      <a href="https://discordapp.com/invite/MFn9Tmz" target="_blank" class="social-link"> <i class="fa fa-commenting" style="padding:10px;"></i> </a>
    </center>
    <p>© 2022 The ETHO Protocol | All Rights Reserved.</p>
  </div>

  <!-- Footer Section End -->
  <!-- Tap on Top -->
  <div class="tap-top">
    <div>
      <i class="fa fa-angle-double-up"></i>
    </div>
  </div>
  <!-- Tap on Ends -->
</div>
</template>
<script>
export default {
  name: 'Footer',
};
</script>
