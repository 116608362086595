<template>

    <v-card
      max-width="600"

      class="px-4 py-4"
    >


      <v-tabs
        background-color="#840032"
        center-active
        dark
        class="pb-3"
        >
        <v-tab @click="login"><img :src='"../assets/images/etho/metamask.png"' height="48" alt="logo">Metamask</v-tab>
        <v-tab ></v-tab>
        <v-tab ></v-tab>
        
        <!--<v-tab @click="showSignupSection">Signup</v-tab>-->
      </v-tabs>


      <v-card v-if="userSection">
        <v-row justify="center" align="center">

          <v-col cols="4" class="pl-8" justify="center" align="center">
            <v-btn color="green" dark small @click="showUploadSection">
              Upload File
              <v-icon light>mdi-upload</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4" class="pl-1" justify="center" align="center">
            <v-btn color="green" dark small @click="showUploadDirectorySection">
              Upload Directory
              <v-icon light>mdi-upload</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4" class="pl-1" justify="center" align="center">
            <v-btn color="green" dark small @click="showViewDataSection">
              View Data
              <v-icon light>mdi-folder</v-icon>
            </v-btn>
          </v-col>

          <!-- <v-col cols="1" class="pl-0" justify="center" align="center">
            <v-btn color="red" dark small @click="removeAll">
              X
              <v-icon light>mdi-logout</v-icon>
            </v-btn>
          </v-col> -->

          <!-- <v-col cols="4" class="pl-2" justify="center" align="center">
            <v-btn color="black" dark small @click="logout">
              Switch Account
              <v-icon light>mdi-logout</v-icon>
            </v-btn>
          </v-col> -->
        </v-row>
      </v-card>


      <v-card class="mt-6" v-if="userSection">
        <!-- <v-row justify="center" align="center"> -->
          
            <div class="container pt-0 pb-3">

               <div class="font-weight-normal" style="margin-top: 8px;position: absolute;right: 0px;">
                  <strong>{{ formatEtho(getAccountValue()) }}<img :src='"../assets/images/etho/etho.png"' height="18" alt="logo" style="margin-top:-3px;margin-right: 5px">~{{ this.acc_value_usd }}<img :src='"../assets/images/etho/dollar-notes.png"' height="24" alt="logo" style="margin-top:-2px;margin-left: 5px;margin-right: 7px"></strong>
                </div>

              <div class="row margin-bottom">

                <div class="col-1 ml-2 mt-1">
                  <a :href="'//'+ethofs.explorer_address+'/address/'+ ethofs.accounts[0]" target="_blank">
                    <img :src="ethofs.utility.getBackgroundImage(ethofs.accounts[0])" height="36" alt="userIDlogo">
                  </a>
                </div>

                <div class="col-10 ml-3">

                    <div class="font-weight-normal">
                      <strong style="font-size:16px">Welcome back,
                       <a style="font-size:15px;color:darkred" :href="'//'+ethofs.explorer_address+'/address/'+ ethofs.accounts[0]" target="_blank">{{ ethofs.accounts[0] }}</a></strong>
                       <!-- <a style="font-size:14px;color:#840032;">{{ ethofs.accounts[0] }}</a></strong> -->
                    </div>

                </div>

              </div>
            </div>

        

        <!-- </v-row> -->
      </v-card>

      <v-card v-if="uploadSection" class="mt-2 pb-2">
        <v-row no-gutters justify="center" align="center">
          <v-col cols="8">
            <v-text-field
              label="Upload Contract Name"
              :readonly="inputs_readonly"
              @change="setContractName"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row justify="center" align="center" class="mt-0">
            <v-col cols="8">

           
              <v-menu
                v-model="menu2" 
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_create"
                    label="Maximum Storage Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_create"
                  :min="getminDate()"
                  :max="getmaxDate()"
                  :readonly="inputs_readonly"
                  @input="menu2 = false"
                  @change="setContractDuration($event)"
                ></v-date-picker>
              </v-menu>
          
            </v-col>

        </v-row>

        <v-row no-gutters justify="center" align="center" class="mt-0">
            <v-col cols="5">

                 <!-- <input id="replications_upload" type="number" min="3" max="50" value="3" @change="setContractReplication($event)"> -->
                  <v-subheader class="pl-6">
                    Replication Factor
                  </v-subheader>
                  <v-slider
                    class="mt-4"
                    id="replications_upload" 
                    max="20"
                    min="3"
                    v-model="replication_slider"
                    :thumb-size="24"
                    :readonly="inputs_readonly"
                    thumb-label="always"
                    
                    @change="setReplication($event)"
                  ></v-slider>

            </v-col>
            <v-col cols="3">
              <v-subheader class="pl-6" style="margin-top:-5px;" v-if="supports_crypto">
                Encrypt File?
              </v-subheader>
              <v-checkbox v-if="supports_crypto"
                class="pl-12"
                id="encryption_box"
                :disabled="inputs_readonly"
                :input-value="useEncryption"
                value
              
                @change="activateEncryption($event)"
              ></v-checkbox>
              <v-subheader no-gutters justify="center" align="center"  class="pl-6" style="margin-top:-5px;" v-if="!supports_crypto">
                Device does not support Encryption
              </v-subheader>


            </v-col>
        </v-row>

        <v-row no-gutters justify="center" align="center" class="mt-0">
          <v-col cols="8">
            <v-file-input
              ref="inputFile"
              show-size
              label="File input"
              :disabled="inputs_readonly"
              @change="selectFile"
            ></v-file-input>
          </v-col>
        </v-row>


        <v-card v-if="fileUploadCost>0 && (currentFile || currentFile_encr)" color="#840032" class="pb-0 pt-0 my-2 mx-6">
          <p class="white--text text-center">
            <!-- Upload Cost:  {{ fileUploadCost }}  ETHO -->
            Upload Cost:  {{ fileUploadCost }} <img :src='"../assets/images/etho/etho2.png"' height="18" alt="logo" style="margin-left:-4px;margin-top:-4px;">
          </p>
        </v-card>


        <v-row justify="center" align="center">
          <v-col cols="4" class="pl-2 pb-4" justify="center" align="center">
            <v-btn color="success" dark small @click="upload" :disabled="inputs_readonly">
              Upload
              <v-icon right dark>mdi-cloud-upload</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </v-card>




      <v-card v-if="uploadDirectorySection" class="mt-2 pb-2">
        <v-row no-gutters justify="center" align="center">
          <v-col cols="8">
            <v-text-field
              label="Upload Contract Name"
              :readonly="inputs_readonly"
              @change="setContractName"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row justify="center" align="center" class="mt-0">
            <!-- <v-col cols="8">
                 <input type="date" :min="getminDate()" :max="getmaxDate()" name="dateofcontract" @change="setContractDuration($event)">
            </v-col> -->

            <v-col cols="8">
              <v-menu
                v-model="menu2" 
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_create"
                    label="Maximum Storage Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_create"
                  :min="getminDate()"
                  :max="getmaxDate()"
                  :readonly="inputs_readonly"
                  @input="menu2 = false"
                  @change="setContractDuration($event)"
                ></v-date-picker>
              </v-menu>
            </v-col>


        </v-row>





        
        <v-row no-gutters justify="center" align="center" class="mt-0">
          <v-col cols="5">

            <!-- <input id="replications_upload" type="number" min="3" max="50" value="3" @change="setContractReplication($event)"> -->
            <v-subheader class="pl-6">
              Replication Factor
            </v-subheader>
            <v-slider
              class="mt-4"
              id="replications_upload" 
              max="20"
              min="3"
              v-model="replication_slider"
              :thumb-size="24"
              :readonly="inputs_readonly"
              thumb-label="always"
              
              @change="setReplication($event)"
            ></v-slider>

            </v-col>
            <v-col cols="3">
            <v-subheader class="pl-6" style="margin-top:-5px;">
              Encrypt File?
            </v-subheader>
            <v-checkbox 
              class="pl-12"
              id="encryption_box"
              :input-value="useEncryption"
              :disabled="inputs_readonly"
              value

              @change="activateEncryption($event)"
            ></v-checkbox>
            </v-col>
        </v-row>

        <!-- <v-row no-gutters justify="center" align="center">
          <v-col cols="8">
            <input id="replications_upload" type="number" min="3" max="50" value="3" @change="setContractReplication($event)">
          </v-col>

        </v-row> -->


        <v-row no-gutters justify="center" align="center" class="mt-0">
          <v-col cols="8">
            <v-file-input
              show-size
              multiple
              webkitdirectory
              COUNTER
              prepend-icon="mdi-folder"
              label="Directory input"
              ref="inputFile"
              :disabled="inputs_readonly"
              @change="selectDirectory"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-card v-if="directoryUploadCost>0 && (currentDirectory || currentDirectory_encr)" color="#840032" class="pb-0 pt-0 my-2 mx-6">
          <p class="white--text text-center">
            <!-- Upload Cost:  {{ fileUploadCost }}  ETHO -->
            Upload Cost:  {{ directoryUploadCost }} <img :src='"../assets/images/etho/etho2.png"' height="18" alt="logo" style="margin-left:-4px;margin-top:-4px;">
          </p>
        </v-card>

        <v-row justify="center" align="center">
          <v-col cols="4" class="pl-2 pb-4" justify="center" align="center">
            <v-btn color="success" dark small @click="uploadDirectory" :disabled="inputs_readonly">
              Upload
              <v-icon right dark>mdi-cloud-upload</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </v-card>



      <v-card v-if="fileInfos.length == 0 && viewDataSection && !showProgress && !message" class="mt-4">
        <v-card-text>
          <strong>You don't have any files yet.</strong>
        </v-card-text>
      </v-card>

      <v-card v-if="fileInfos.length > 0 && viewDataSection && !showProgress && !message" class="mt-4">
        <v-dialog
          v-model="extensionConfirm"
          persistent
          max-width="290" 
        >
          <v-card>
            <v-card-title class="headline">
              Confirmation
            </v-card-title>

            <v-card-text>
                Extend {{ expandDays }} days for {{ expandPrice }} <img :src='"../assets/images/etho/etho.png"' height="18" alt="logo"> ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="cancelExtension"
              >
                Cancel
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="confirmExtension"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>



        <v-dialog
          v-model="removalConfirm"
          persistent
          max-width="290"
        >
          <v-card >
            <v-card-title class="headline">
              Delete Contract
            </v-card-title>

            <v-card-text>
              Continue with contract deletion?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="cancelRemoval"
              >
                Cancel
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="confirmRemoval"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        
        <v-dialog
          v-model="setownerConfirm"
          persistent
          max-width="350"
        >
          <v-card >
            <v-card-title class="marg-20">
              Set New Owner
            </v-card-title>

            <v-card-text>
              Please enter new owner address
            </v-card-text>

            <input value="0x1412412" id="newowner" style="
                border: 1px solid #000000;
                border-style: dotted;
                color: black;
                width: 100%;
                height: 30px;
                font-size: 14px;
                background-color: lightgrey;
                text-align: center;
            ">

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="cancelSetOwner"
              >
                Cancel
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="confirmSetOwner"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog
          v-model="changeownerConfirm"
          persistent
          max-width="290"
        >
          <v-card >
            <v-card-title class="marg-20">
              Confirmation
            </v-card-title>

            <v-card-text>
              Contract <strong>{{ getShortContractName() }} </strong> will change owner to :<strong> {{this.newowner  }}</strong>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="cancelChangeOwner"
              >
                Cancel
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="confirmChangeOwner"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>



        <v-data-table :headers="headers" :items="fileInfos" height="34vh">
            <!-- <v-subheader>Upload Contracts</v-subheader> -->
            <template v-slot:item.contractName="{ item }">

                <!-- <v-row justify="center" align="center">
                    <div>
                        {{item.hostingContractName}}
                    </div>
                    <div>
                        <v-btn :href="'//data.ethoprotocol.com/ipfs/' + item.mainContentHash" x-small target="_blank">View</v-btn>
                    </div>
                </v-row> -->
                
                    <!-- <v-btn @click="openDownload(item.mainContentHash)" x-small target="_blank" style="width:125px">{{getShortContractName2(item.hostingContractName)}}</v-btn> -->
                    <v-btn :href="'//data.ethoprotocol.com/ipfs/' + item.mainContentHash" x-small target="_blank" style="width:105px">{{getShortContractName2(item.hostingContractName)}}</v-btn>
                    <!-- <iframe
                        :src="'//data.ethoprotocol.com/ipfs/' + item.mainContentHash"
                        width="100%"
                        height="100"
                        frameborder="0" 
                        id="contenttest">
                      </iframe> -->

            </template>
            <template v-slot:item.contractStorageUsed="{ item }">
                <!--{{ getStorageSize(item.contractStorageUsed) }} MB-->
                <v-row justify="center" align="center">
                  {{ getStorageSize(item.contractStorageUsed) }}
                  <!-- <v-icon light>mdi-folder</v-icon> -->
                </v-row>

            </template>
            <!-- <template v-slot:item.expiration_date="{ item }">
                {{ getExpirationDate(item) }}
            </template> -->
            <template v-slot:item.hostingReplicationFactor="{ item }" style="margin-right:2px">

                     <!-- <v-row>  -->
                     <v-row justify="center" align="center"> 
                        <!--<v-col cols="4" class="pl-2" justify="center" align="center">-->

                        <!--<div>-->
                            <!-- {{ formatEtho(item.hostingReplicationFactor) }} <img :src='"../assets/images/etho/etho.png"' height="18" alt="logo"> -->
                            {{ item.hostingReplicationFactor }}
                        <!--</div>-->
                        <!--<div> 
                            {{ getStorageSize(item.contractStorageUsed) }} MB <v-icon light>mdi-folder</v-icon> 
                        </div>-->


                    </v-row>

            </template>

            

            <!-- <template v-slot:item.dataLink="{ item }">
                <v-btn :href="'//data.ethoprotocol.com/ipfs/' + item.ipfsHash" x-small target="_blank">View</v-btn>
            </template> -->
            <!-- <template v-slot:item.addressLink="{ item }">
                <v-btn :href="'//explorer.ethoprotocol.com/address/' + item.address" x-small target="_blank">View</v-btn>
            </template> -->
            <template v-slot:item.ExpirationTime="{ item }">
               <!-- <v-btn x-small @click="showConfirmRemoval(item)">Remove Contract</v-btn> -->


                <input type="date" name="dateofcontract" :min="getExpirationDateMin(item)" :max="getmaxDate()" :value="ethofs.getExpirationDate(item)" @change="showConfirmExtension(item,$event)">
   
                <!-- <v-dialog
                  ref="dialog"
                  v-model="menu2"
                  :return-value.sync="date_extend"
                  persistent
                  width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_extend"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_extend"
                  :min="getExpirationDate(item)"
                  :max="getmaxDate()"
                  :readonly="inputs_readonly"
                  @input="menu2 = false"
                  @change="showConfirmExtension(item,$event)"
                ></v-date-picker>
              </v-dialog> -->

            </template>


            <template v-slot:item.contractActions="{ item }">
                <v-row justify="center" align="center">
                  <v-icon light @click="openDownload(item.mainContentHash)">mdi-download</v-icon>
                  <v-icon light @click="showSetOwner(item)">mdi-account</v-icon>
                  <v-icon light @click="showConfirmRemoval(item)">mdi-delete</v-icon>
                </v-row>
           </template>
  

            </v-data-table>
          </v-card>

           <v-card v-if="(showProgress)" class="mt-4">
          <div>
            <div>
              <v-progress-linear
              :value="ethofs.upload_status"
              color="light-green"
              height="25"
   
              reactive
              
              >
                  <strong>{{ ethofs.upload_status }} <b-spinner style="width: 11px; height: 11px;margin-left:2px;"  label="Small Spinner" type="grow"></b-spinner> </strong>
              </v-progress-linear>
            </div>
          </div>
          </v-card>

          <v-alert v-if="message" border="left" color="blue-grey" dark class="mt-4">
            {{ message }}
          </v-alert>
        </v-card>
    </template>

    <script>
    // import UploadService from "../services/UploadFilesService";
    // import { reject } from "q";
import EthoFS from "../services/EthoFS";
// import JSZip from "../plugins/jszip";
import JSZip from 'jszip'

    export default {
      name: "upload-files",
      data() {
          return {
          networkID: '5777',
          replication_slider: 10, // same as contractReplication
          // date_create:"2018-03-20", // just placeholder?
          date_create:new Date(new Date().getTime() + 60 * 60 * 24 * 1000).toISOString().split('T')[0], // just placeholder?
          date_extend:"2018-03-20", // just placeholder?
          // menu: false,
          // modal: false,
          menu2: false,
          ethofs: EthoFS,
          acc_value: 0,
          acc_value_usd: 0,
          fileUploadCost: 0,
          directoryUploadCost: 0,
          expandDays: 0,
          inputs_readonly:false,
          expandPrice: 0,
          NEWOWNER_ERRORNAME:"ERROR CONTRACT ADDRESS",
          newowner:"ERROR CONTRACT ADDRESS",
          extensionConfirm: false,
          removalConfirm: false,
          changeownerConfirm: false,
          setownerConfirm: false,
          uploadSection: false,
          uploadDirectorySection: false,
          loginSection: true,
          viewDataSection: false,
          userSection: false,

          lastFileSelection: null,
          currentFile: undefined,
          currentDirectory: undefined,

          lastDirectorySelection: null,
          currentFile_encr: undefined,
          currentDirectory_encr: undefined,

          accountName: undefined,
          contractName: undefined,
          useEncryption: false,
          contractCallback: undefined,
          contractDuration: 1,
          contractReplication:10,
          progress: 0,
          // progressBarMessage: "nomsg",
          showProgress: false,
          supports_crypto: window.crypto !== undefined,

          // upload_files_temp_cache = [],
          // upload_files_temp_cache_encr = [],

          MESSAGE_FILE : "Please select a file",
          MESSAGE_DIRECTORY : "Please select a directory",
          MESSAGE_NAME : "Please enter upload contract name",
          MESSAGE_DURATION : "Please select upload contract duration",
          MESSAGE_REPFACTOR : "Please select upload contract replication factor",
          MESSAGE_UPLOADFAILED : "Could not upload the file",
          MESSAGE_INVALIDCONTRACTSELECTED : "Please select a valid contract",

          message: "",
          fileInfos: [],
          headers: [
              {
                text: 'Contract Name',
                align: 'start',
                sortable: false,
                value: 'contractName',
              },
              //{ text: 'Data', value: 'dataLink' },
              { text: 'Storage', value: 'contractStorageUsed' ,sortable:true},
              // { text: 'Storage Cost', value: 'storagecost_etho' },
              //{ text: 'Expiration (Date)', value: 'expiration_date' },
              { text: 'Expiration Date', value: 'ExpirationTime', width:'10px', sortable: true},
              { text: 'Copies', align: 'start', value: 'hostingReplicationFactor', sortable: true},
              { text: 'Action', align: 'start', value: 'contractActions', sortable: false, width:'100px'},
          ],
        };
      },
      methods: {
        async calculateFileUploadCost(size) {
          EthoFS.get_hosting_cost(size, this.contractDuration, this.contractReplication).then(response => { // change 3 later
          //UploadService.calculateCost(size, this.contractDuration).then(response => {
            console.log(response);
            // this.fileUploadCost = (Number(response.data.uploadCost) / 1000000000000000000).toFixed(8);
            this.fileUploadCost = (Number(response) / 1000000000000000000).toFixed(8);
            return true;
          });
        },
        async calculateDirectoryUploadCost(size) {
          // UploadService.calculateCost(size, this.contractDuration, this.contractReplication).then(response => {
          //   console.log(response);
          //   this.directoryUploadCost = (Number(response.data.uploadCost) / 1000000000000000000).toFixed(8);
          //   return true;
          // });
          console.log("->size",size)
          EthoFS.get_hosting_cost(size, this.contractDuration, this.contractReplication).then(response => { // change 3 later
          //UploadService.calculateCost(size, this.contractDuration).then(response => {
            console.log(response);
            // this.directoryUploadCost = (Number(response.data.uploadCost) / 1000000000000000000).toFixed(8);
            this.directoryUploadCost = (Number(response) / 1000000000000000000).toFixed(8);
            return true;
          });

        },

        showViewDataSection() {
          // this.getAccountValueUSD()
          // console.log("JSZip",JSZip)
          this.loginSection = false;
          this.uploadSection = false;
          this.uploadDirectorySection = false;
          this.viewDataSection = true;
          this.userSection = true;
          this.contractCallback = undefined;
          this.accountName = undefined;
          this.contractName = undefined;
          this.contractDuration = 1;
          this.contractReplication = 10;
          this.lastFileSelection = null
          this.lastDirectorySelection = null
          this.currentFile = undefined;
          this.currentFile_encr = undefined;
          this.currentDirectory = undefined;
          this.currentDirectory_encr = undefined;
          this.showProgress = false;
          this.inputs_readonly = false;
          this.fileUploadCost = 0;


          this.message = "";
        },
        showUploadSection(is_reset) {
          this.loginSection = false;
          this.uploadSection = true;
          this.uploadDirectorySection = false;
          this.viewDataSection = false;
          this.userSection = true;
          this.contractCallback = undefined;
          this.accountName = undefined;
          this.inputs_readonly = false;

          if(!is_reset) {
            this.resetFileLoad()
            this.contractDuration = 1;
            this.contractReplication = 10;
            this.contractName = undefined;
            this.lastFileSelection = null
            this.lastDirectorySelection = null
            this.currentFile = undefined;
            this.currentFile_encr = undefined;
            this.currentDirectory = undefined;
            this.currentDirectory_encr = undefined;
          }
          

          this.message = "";
        },
        showUploadDirectorySection(is_reset) {
          this.loginSection = false;
          this.uploadDirectorySection = true;
          this.uploadSection = false;
          this.viewDataSection = false;
          this.userSection = true;
          this.contractCallback = undefined;
          this.accountName = undefined;
          this.inputs_readonly = false;

          if(!is_reset) {
            this.resetFileLoad()
            this.contractDuration = 1;
            this.contractReplication = 10;
            this.contractName = undefined;
            this.lastFileSelection = null
            this.lastDirectorySelection = null
            this.currentFile = undefined;
            this.currentFile_encr = undefined;
            this.currentDirectory = undefined;
            this.currentDirectory_encr = undefined;
          }

          this.message = "";
        },
        showConfirmExtension(_contract, _event) {
        // showConfirmExtension(_contract, _value) {
          this.contractCallback = _contract;
          this.contractDuration = 1;
          this.contractReplication = 10;
          this.extensionConfirm = true;

          let _currentEndDate_unix = _contract["creationTimestamp"] * 1000 + _contract["deployedDaysLength"] * 60 * 60 * 24 * 1000
          //let _currentEndDate = new Date(_currentEndDate_unix).toISOString().split('T')[0]
          //let days_extend = parseInt((new Date(document.getElementById('extend_contract_date').value).getTime() - _currentEndDate_unix) / (60 * 60 * 24 * 1000)) + 1
          let days_extend = parseInt((new Date(_event.target.value).getTime() - _currentEndDate_unix) / (60 * 60 * 24 * 1000)) + 1
          // let days_extend = parseInt((new Date(_value).getTime() - _currentEndDate_unix) / (60 * 60 * 24 * 1000)) + 1


          this.expandDays = days_extend

          EthoFS.get_hosting_cost(parseInt(_contract["contractStorageUsed"]), days_extend, parseInt(_contract["hostingReplicationFactor"])).then((_uploadCost) => {
            this.expandPrice = this.formatEtho(_uploadCost)
          })

          //this.expandPrice = 0.0000532

          console.log("days_extend", days_extend, _event)
          // console.log("days_extend", days_extend, _value)

            
        },
        confirmExtension() {
          this.extensionConfirm = false;
          this.extend(); 
        },
        cancelExtension() {
          this.extensionConfirm = false;
          this.contractCallback = undefined;
          this.contractDuration = 1;
          this.contractReplication = 10;
        },
        showConfirmRemoval(_contract) {
          this.contractCallback = _contract;
          this.removalConfirm = true;
        },
        confirmRemoval() {
          this.removalConfirm = false;
          this.remove();
        },
        cancelRemoval() {
          this.removalConfirm = false;
          this.contractCallback = undefined;
        },
        showSetOwner(_contract) {
          this.contractCallback = _contract;
          this.setownerConfirm = true;
        },
        confirmSetOwner() {
          var newowner = document.getElementById('newowner').value
          if (EthoFS.utility.validate_wallet_address(newowner)) {
            this.newowner = EthoFS.utility.validate_wallet_address(newowner)
            this.setownerConfirm = false;
            this.setowner();
            this.showConfirmChangeOwner();
          } else {
            this.newowner = this.NEWOWNER_ERRORNAME
            document.getElementById('newowner').value = this.NEWOWNER_ERRORNAME
          }

        },
        cancelSetOwner() {
          this.setownerConfirm = false;
          this.contractCallback = undefined;
          this.newowner = "ERROR";
        },
        showConfirmChangeOwner() {
          this.changeownerConfirm = true;
        },
        confirmChangeOwner() {
          this.changeownerConfirm = false;
          this.changeowner();
        },
        cancelChangeOwner() {
          this.changeownerConfirm = false;
          this.contractCallback = undefined;
        },
        setKey(key) {
          // this.progress = 0;
          this.ethoProtocolKey = key;
        },
        setAccountName(name) {
          // this.progress = 0;
          this.accountName = name;
        },
        getEthoPrice() {
          return new Promise((resolve) => {
            let xmlHttp = new XMLHttpRequest();
            xmlHttp.open( "GET", "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=usd", false );
            xmlHttp.send( null );
            let jsonData = JSON.parse(xmlHttp.responseText)
            console.log("etho_price","--",jsonData,jsonData["ether-1"]["usd"])
            if (jsonData["ether-1"] !== undefined) {
              resolve(parseFloat(jsonData["ether-1"]["usd"]))
            } else {
              resolve(-1)
            }
            // return xmlHttp.responseText;
          })
        },

        // downloadFile(_base64) {
        downloadFile(_base64) {
          // let data = Uint8Array.from(this.ethofs.encryption.base64_to_buf(_base64[1])); // content
          // let data = Uint8Array.from(_base64[1]); // content
          // var content = new Blob([data.buffer], { type: attach.contentType });
          // var content = new Blob([data.buffer], { type: "txt" });
          // let content = new Blob([data.buffer]);
          // let content = new Blob([_base64[1]]); 
          // let content = new Blob([_base64[1]], {
          //     type: 'text/plain'
          // });

          // let encodedUri = window.URL.createObjectURL(content);
          let encodedUri = window.URL.createObjectURL(_base64[1]);
          let link = document.createElement("a");

          link.setAttribute("href", encodedUri);
          link.setAttribute("download", _base64[0]); // name

          link.click();
          this.message = "";
        },

        downloadFiles(_total_files) {
          console.log("JSZip",JSZip)
            let _this = this
            this.zipFiles(_total_files).then(function(zip) {
              console.log("zip2",zip)
              zip.generateAsync({type:"blob"}).then(function(content) {
                  // see FileSaver.js
                  // saveAs(content, "example.zip");
                  let encodedUri = window.URL.createObjectURL(content);
                  let link = document.createElement("a");

                  link.setAttribute("href", encodedUri);
                  link.setAttribute("download", "example.zip"); // name

                  link.click();

                  _this.message = "";
              });
            })
        },

        async zipFiles(_total_files) {
          var zip = new JSZip();
          var _count = 0
          // return new Promise((resolve) => {
            for (var i = 0; i <= _total_files.length - 1; i++) {
              var _file = _total_files[i]

              // img.file(_file[1], _file[2], {base64: true});
              _count ++
              if (_file[0] == 1) {
                var _decryptedRAW = await this.ethofs.encryption.FullDECRYPT(_file[2])
                // this.ethofs.encryption.FullDECRYPT(_file[2]).then((_decryptedRAW) => {
                  console.log("ADDING DECYRPTED CONT")
                  let path1 = _file[1]
                  let path2 = _decryptedRAW[0]
                  let last_index = path1.lastIndexOf('/')
                  let path_new = ""
                  if (last_index) {
                    path_new = path1.slice(0,last_index+1) + path2 //subroot file
                  } else {
                    path_new = path2 // rootfile
                  }
                  
                  console.log("path1",path1,"path2",path2,"path_new",path_new)

                  // let data = Uint8Array.from(this.ethofs.encryption.base64_to_buf(_base64[1])); // content
                  // let content = new Blob([data.buffer]);

                  // zip.file(path_new, _decryptedRAW[1]);
                  // zip.file(path_new, this.ethofs.encryption.base64_to_buf(_decryptedRAW[1]));
                  zip.file(path_new, _decryptedRAW[1]);
                // })
              } else {
                console.log("NOT ENCRYPTED",_file)
                // let arraybuf = new ArrayBuffer(Uint8Array.from(_file[2]))
                // console.log("arraybuf",arraybuf)
                // zip.file(_file[1],arraybuf.buffer);

                // new TextEncoder().encode("This is a string converted to a Uint8Array")
                // zip.file(_file[1],(new TextEncoder().encode(_file[2])).buffer);
                // zip.file(_file[1],Uint8Array.from(atob(btoa(String.fromCharCode(..._file[2]))), (c) => c.charCodeAt(null)).buffer);
                // zip.file(_file[1],Uint8Array.from(btoa(String.fromCharCode(..._file[2]))).buffer);
                // zip.file(_file[1],Uint8Array.from(btoa(String.fromCharCode(..._file[2]))));
                // zip.file(_file[1],btoa(String.fromCharCode(..._file[2])));
                // zip.file(_file[1],URL.createObjectURL(new Blob([Uint8Array.from(_file[2]).buffer])));
                // zip.file(_file[1],URL.createObjectURL(Uint8Array.from(_file[2]).buffer));
                // console.log(new TextDecoder(_file[2]))
                zip.file(_file[1],_file[2], {binary:true});
                // zip.file(_file[1],_file[2]);
                // zip.file(_file[1],new Blob([_file[2]]));
                // zip.file(_file[1],Uint8Array.from(_file[2]).buffer);
                // zip.file(_file[1],Uint8Array.from(_file[2]).buffer);
                // zip.file(_file[1], new Blob([Uint8Array.from(_file[2])]));
   
              }
              if (_count == _total_files.length ) {
                console.log("--------------------------------")
                console.log("zip1",zip)
                return zip
              }

            }
          // })

        },

        handleFiles(_total_files) {
          if (_total_files.length == 1) {
            console.log("single file result",_total_files[0])
            // if (_is_encrypted) {
            if (_total_files[0][0] == 1) { // encryption active on file
              this.ethofs.encryption.FullDECRYPT(_total_files[0][2]).then((_decryptedRAW) => {

                console.log("_decryptedRAW",_decryptedRAW)
                this.downloadFile([_decryptedRAW[0],new Blob([_decryptedRAW[1]])])
                // this.downloadFile(_decryptedRAW)

              })
            } else {
              // this.downloadFile([_total_files[0][1],Uint8Array.from(_total_files[0][2]).buffer]) // DOES THIS ALWAYS WORK WITH UINT8ARRAY ??
              console.log("DOWNLOAD 2")
              // this.downloadFile([_total_files[0][1],Uint8Array.from(_total_files[0][2]).buffer]) // DOES THIS ALWAYS WORK WITH UINT8ARRAY ??
              
              // BINARY TO UINT8
              // let d = _total_files[0][2];
              // let l = d.length;
              // let array = new Uint8Array(l);
              // for (var i = 0; i < l; i++){
              //     array[i] = d.charCodeAt(i);
              // }

              // this.downloadFile([_total_files[0][1],array]) // DOES THIS ALWAYS WORK WITH UINT8ARRAY ??

              // this.downloadFile([_total_files[0][1],Uint8Array.from(_total_files[0][2])]) // DOES THIS ALWAYS WORK WITH UINT8ARRAY ??
              this.downloadFile([_total_files[0][1],_total_files[0][2]])
              // this.downloadFile(_total_files[0][1],_total_files[0][2])
            }
          
          } else {
            console.log("create zip download for multiple files",_total_files)


            // var blob = new Blob( ["test"], { type: "text/plain" } );
            console.log("JSZip",JSZip)
            

            // zip.file("Hello.txt", "Hello World\n");

            // const img = zip.folder("images");

            // var zip = this.zipFiles(_total_files)
            // this.zipFiles(_total_files).then(function(zip) {
            //   console.log("zip2",zip)
            //   zip.generateAsync({type:"blob"}).then(function(content) {
            //       // see FileSaver.js
            //       // saveAs(content, "example.zip");
            //       let encodedUri = window.URL.createObjectURL(content);
            //       let link = document.createElement("a");

            //       link.setAttribute("href", encodedUri);
            //       link.setAttribute("download", "example.zip"); // name

            //       link.click();
            //   });
            // })

            this.downloadFiles(_total_files);

            // zip.file( 'file.txt' , blob );

            // zip.generateAsync( { type: 'blob', compression: 'DEFLATE' } )
            // .then( function( zipFile ){ 

            //     console.log(zipFile)

            // }, function( error ){ 

            //     console.log( 'Error in compression' ,error);

            // } );



          }
        },

        openDownload(CID_HASH) {
          this.message = "Downloading CID: "+CID_HASH
          this.collectDownload(CID_HASH)
        },

        async collectDownload(CID_HASH) {
          // this.message = "Reading your files from IPFS..."

          this.message = "Downloading CID: "+CID_HASH

          let [_is_encrypted,_total_files] = await this.ethofs.ipfs_handler.getRawIPFScontent(CID_HASH)
          console.log("_is_encrypted",_is_encrypted)
          console.log("_total_files",_total_files)

          

          if (_is_encrypted) { // encrypted files never use array higher than uint8
            this.updateEncryption().then((_worked) => {
                if (_worked) {
                  this.message = "Decrypting CID: "+CID_HASH
                  console.log("getting dec key worked",_worked)
                  this.handleFiles(_total_files)
                } else {
                  console.log("error with getting decryption key")
                }
            })

          } else { // careful with file encodings... larger than uint8 latin.-1 etc
            this.handleFiles(_total_files)
          }



          // console.log(_fileRAW)

          // if (_fileRAW.substring(0,11) == "ethoencode(") {
          // if (_fileRAW.substring(0,9) == "ethoname(") {

          // console.log("DECRYPTIONLOG:SINGLE FILE")

          // this.updateEncryption().then((_worked) => {
          //     if (_worked) {

          //       this.ethofs.encryption.FullDECRYPT(_fileRAW).then((_decryptedRAW) => {

          //         console.log("_decryptedRAW",_decryptedRAW)

          //       })
          //     }
          // })
      
                    // this.downloadFile(_decryptedRAW)
                    // x.document.open().write(atob(_decryptedRAW));
                    // x.document.body.innerHTML

          // } else {
            // console.log("DECRYPTIONLOG:FOLDER TYPE")

            // var x=window.open();
            // x.document.open().write(_fileRAW);
            // x.document.body.innerHTML
          // }

          // return new Promise((resolve) => {

          // })
        },

        selectFile(file) {
          // this.progress = 0;
          if (file) {
            // this.currentFile = file;

            this.lastFileSelection = file;

            if (this.useEncryption) {
              console.log("START ENCRYPTION ON SINGLE FILE")
              EthoFS.loadFileObject(file,true).then((readstream) => {
                
                this.fileSize_encr = readstream[1];
                this.calculateFileUploadCost(readstream[1]);

                this.currentFile_encr = readstream[0]
                console.log("this.currentFile_encr",this.currentFile_encr)
              })
            } else {
              EthoFS.loadFileObject(file,false).then((readstream) => {
                this.fileSize = file.size;
                this.calculateFileUploadCost(file.size);

                this.currentFile = readstream[0]
                console.log("this.currentFile",this.currentFile)
              })
              // this.currentFile = file
            }



            // if (this.message != "") {
            if (this.message == this.MESSAGE_FILE) {
              this.message = "";
            }
           

          } else {
            console.log("FILE WAS DELETED BY USER?")
            this.fileUploadCost = 0;
            this.currentFile = undefined;
            this.currentFile_encr = undefined;
            this.lastFileSelection = null;
          }

        },
        selectDirectory(directory) {
          console.log(directory);

          if (directory) {
            // this.progress = 0;
            // this.currentDirectory = directory;

            this.lastDirectorySelection = directory;

            if (this.useEncryption) {
              console.log("START ENCRYPTION ON SINGLE DIRECTORY")
              console.log("directory",directory)
              EthoFS.loadFileObject(directory,true).then((readstream) => {
                console.log("readstream",readstream)
                this.directorySize_encr = readstream[1];
                this.calculateDirectoryUploadCost(readstream[1]);

                this.currentDirectory_encr = readstream[0]
                console.log("this.currentFile_encr",this.currentFile_encr)
              })
            } else {
              EthoFS.loadFileObject(directory,false).then((readstream) => {

                var size = 0;
                for (var i = 0; i <= directory.length - 1; i++) {
                  size += directory[i].size;
                  if(i == (directory.length - 1)) {
                    this.directorySize = size;
                    this.calculateDirectoryUploadCost(size);
                  }
                }


                this.currentDirectory = readstream[0]
                console.log("this.currentDirectory",this.currentDirectory)
              })
              // this.currentDirectory = directory
            }




            if (this.message == this.MESSAGE_DIRECTORY) {
              this.message = "";
            }

          } else {
            console.log("DIRECTORY WAS DELETED BY USER?")
            this.directoryUploadCost = 0;
            this.currentDirectory = undefined;
            this.currentDirectory_encr = undefined;
            this.lastDirectorySelection = null;
          }

        },
        setContractName(name) {
          // this.progress = 0;
          this.contractName = name;
          if (this.message == this.MESSAGE_NAME) {
            this.message = "";
          }
        },
        updateEncryption() {
          return new Promise((resolve) => {
            if (!EthoFS.encryption.hasPassword()) {
              let encr_key = 'enc_key_'+this.ethofs.accounts[0]
              if (localStorage.getItem(encr_key) === null) {
                EthoFS.request_sign("metamask","create encryption key").then((new_password) => {
                  new_password = new_password.substr(2,66) // fix for shorter length

                  console.log("new_password",new_password)
                  console.log("encr_key",encr_key)
                  console.log("localStorage.getItem(encr_key)",localStorage.getItem(encr_key))

                  EthoFS.encryption.setPassword(new_password).then((pw_result) => {
                    if (pw_result) {
                      localStorage.setItem(encr_key, new_password)
                      resolve(true)
                    }
                })


  
                })
              } else {

                EthoFS.encryption.setPassword(localStorage.getItem(encr_key)).then((pw_result) => {
                    if (pw_result) {
                      resolve(true)
                    }
                })
                
                
              }
            } else {
              console.log("ALREADY HAS PASSWORD")
              // reject("FIXLATER")
              resolve(true)
            }
          })

        },
        setEncryption() {
          this.useEncryption = true;
          console.log("SETTING ENCRYPTION",this.lastFileSelection,this.lastDirectorySelection)
          if (this.lastFileSelection) {
            if (!this.currentFile_encr) { // only encrypt once
              console.log("START ENCRYPTION ON SINGLE FILE")
              EthoFS.loadFileObject(this.lastFileSelection,true).then((readstream) => {

                this.fileSize_encr = readstream[1];
                this.calculateFileUploadCost(readstream[1]);

                this.currentFile_encr = readstream[0]
                console.log("this.currentFile_encr",this.currentFile_encr)
              })
            } else {
              this.calculateFileUploadCost(this.fileSize_encr); // recalc the directory due to rep factor?
            }


          } else if (this.lastDirectorySelection) {
            console.log("START ENCRYPTION ON DIRECTORY")
            if (!this.currentDirectory_encr) { // only encrypt once
              EthoFS.loadFileObject(this.lastDirectorySelection,true).then((readstream) => {

              this.directorySize_encr = readstream[1];
              this.calculateDirectoryUploadCost(readstream[1]);

              this.currentDirectory_encr = readstream[0]
              console.log("this.currentDirectory_encr",this.currentDirectory_encr)
              })
            } else {
              this.calculateDirectoryUploadCost(this.directorySize_encr); // recalc the directory due to rep factor?
            }

          }

        },
        activateEncryption(option) {
          console.log("ENCRYPTION IS ACTIVTED!",option)
         
          if (option == false) {
            this.useEncryption = false;
            if (this.lastFileSelection) {

              this.fileSize = this.lastFileSelection.size;
              this.calculateFileUploadCost(this.lastFileSelection.size);

              if (!this.currentFile) {
                console.log("LOAD NON-ENCRYPTION SINGLE FILE")
                EthoFS.loadFileObject(this.lastFileSelection,false).then((readstream) => {



                  this.currentFile = readstream[0]
                  console.log("this.currentFile",this.currentFile)
                })
              }

            } else if (this.lastDirectorySelection) {
              var size = 0;
              for (var i = 0; i <= this.lastDirectorySelection.length - 1; i++) {
                size += this.lastDirectorySelection[i].size;
                if(i == (this.lastDirectorySelection.length - 1)) {
                  this.directorySize = size;
                  this.calculateDirectoryUploadCost(size);
                }
              }

              if (!this.currentDirectory) {
                console.log("LOAD NON-ENCRYPTION DIRECTORY")
                EthoFS.loadFileObject(this.lastDirectorySelection,false).then((readstream) => {

                  this.currentDirectory = readstream[0]
                  console.log("this.currentDirectory",this.currentDirectory)
                })
              }
            }
              
          } else {
            if (!EthoFS.encryption.hasPassword()) {
              let encr_key = 'enc_key_'+this.ethofs.accounts[0]
              if (localStorage.getItem(encr_key) === null) {
                EthoFS.request_sign("metamask","create encryption key").then((new_password) => {
                  console.log("new_password",new_password)
                  if (new_password) {
                    new_password = new_password.substr(2,66) // fix for shorter length

                    console.log("new_password",new_password)
                    console.log("encr_key",encr_key)
                    console.log("localStorage.getItem(encr_key)",localStorage.getItem(encr_key))

                    EthoFS.encryption.setPassword(new_password).then((pw_result) => {
                      if (pw_result) {
                        console.log("LOLAAAOLL",pw_result)
                        localStorage.setItem(encr_key, new_password)


                        this.setEncryption()
                      } else {
                        console.log("UNCHECK BOX")
                        // option = false
                        document.getElementById('encryption_box').click()
                      }

                    })
                  } else {
                    console.log("UNCHECK BOX")
                    // option = false
                    document.getElementById('encryption_box').click()
                  }
      

                })
              } else {
                EthoFS.encryption.setPassword(localStorage.getItem(encr_key)).then((pw_result) => {
                  if (pw_result) {
                    this.setEncryption()
                  }

                })
         
              }
            } else {
              this.setEncryption()
            }
          }
        },

        // setContractDuration(_event) {
        setContractDuration(_value) {
          // console.log("_value",_value)
          // let days_deploy = parseInt((new Date(_event.target.value).getTime() - Date.now()) / (60 * 60 * 24 * 1000)) + 1 // should return how many days into future from now
          let days_deploy = parseInt((new Date(_value).getTime() - Date.now()) / (60 * 60 * 24 * 1000)) + 1 // should return how many days into future from now

          // this.date_create = new Date(new Date(_value).getTime() + 60 * 60 * 24 * 1000).toISOString().split('T')[0]
          // this.date_create = "2018-03-20"
          console.log(new Date(new Date(_value).getTime()).toISOString().split('T')[0])
          // this.progress = 0;
          this.contractDuration = days_deploy;
          if(this.currentFile != undefined && !this.useEncryption) {
            this.calculateFileUploadCost(this.fileSize);
          } else if(this.currentFile_encr != undefined) {
            this.calculateFileUploadCost(this.fileSize_encr);
          } else if(this.currentDirectory != undefined && !this.useEncryption) {
            this.calculateDirectoryUploadCost(this.directorySize);
          } else if(this.currentDirectory_encr != undefined) {
            this.calculateDirectoryUploadCost(this.directorySize_encr);
          }

          if (this.message == this.MESSAGE_DURATION) {
            this.message = "";
          }

        },
        // setContractReplication(_event) {
        setReplication(_value) {
          // console.log(_event)
          // console.log(_event.value)
          // let repl_factor = parseInt(_event.target.value)
          let repl_factor = parseInt(_value)
          this.contractReplication = repl_factor;
          if(this.currentFile != undefined && !this.useEncryption) {
            this.calculateFileUploadCost(this.fileSize);
          } else if(this.currentFile_encr != undefined) {
            this.calculateFileUploadCost(this.fileSize_encr);
          } else if(this.currentDirectory != undefined && !this.useEncryption) {
            this.calculateDirectoryUploadCost(this.directorySize);
          } else if(this.currentDirectory_encr != undefined) {
            this.calculateDirectoryUploadCost(this.directorySize_encr);
          }

          if (this.message == this.MESSAGE_REPFACTOR) {
            this.message = "";
          }

        },
        getContractName() {
          console.log("this.contractCallback",this.contractCallback)
          return this.contractCallback ? this.contractCallback.hostingContractName : "NO CONTRACT SELECTED"
        },
        getShortContractName() {
          return this.getContractName().length > 18 ? this.getContractName().slice(0,15)+"..." : this.getContractName()
        },
        getShortContractName2(c_name) {
          return c_name.length > 18 ? c_name.slice(0,15)+"..." : c_name
        },
        getAccountValue() {
            return this.acc_value
        },
        // getAccountValueUSD() {
          
        //   this.getEthoPrice().then((etho_price) => {
        //     console.log("etho_price",etho_price)
        //     if (etho_price !== -1) {
        //       console.log("etho_price 2",this.formatEtho(this.getAccountValue()) * etho_price)
        //       // return "(" + (this.formatEtho(this.getAccountValue()) * etho_price) + " USD)"
        //       this.acc_value_usd =  "(" + (this.formatEtho(this.getAccountValue()) * etho_price) + " USD)"
        //     }
        //   })
        //   return 0.01

        // },

        // is_using_encryption() {
        //   return this.useEncryption
        // },
        formatEtho(_amt) {
            return (parseInt(_amt) / 1000000000000000000).toFixed(8)
            // let _ETHO = (parseInt(_amt) / 1000000000000000000).toFixed(8)
            // console.log(this.ethoprice)
            // return _ETHO + " " +parseFloat(_ETHO)*this.ethoprice
        },
        formatUSD(_amt) {
            return (parseInt(_amt) / 1000000000000000000).toFixed(2)
        },
        getStorageSize(_size) {
          let newsize = parseInt(_size)
          console.log(_size)
          if (newsize < 1000) {
            return parseInt(_size).toFixed(2) + " B"
          } else if (newsize < 1000000) {
            return (parseInt(_size) / 1000).toFixed(2) + " KB"
          } else if (newsize < 1000000000) {
            return (parseInt(_size) / 1000000).toFixed(2) + " MB"
          } else {
            return (parseInt(_size) / 1000000000).toFixed(2) + " GB"
          }
            
        },
        getminDate() {
          // this.date_create = new Date(new Date().getTime() + 60 * 60 * 24 * 1000).toISOString().split('T')[0]
          return new Date(new Date().getTime() + 60 * 60 * 24 * 1000).toISOString().split('T')[0]
          // return (new Date(Date.now() + 60 * 60 * 24 * 1000 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

        },
        getmaxDate() { //date_extend
          // console.log("new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 5).toISOString().split('T')[0]",new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 5).toISOString().split('T')[0])
          // return new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 5).toISOString().split('T')[0]
          return new Date(new Date().getTime() + 10 * 365.25 * 60 * 60 * 24 * 1000).toISOString().split('T')[0]
          // return (new Date(Date.now() + 10 * 365.25 * 60 * 60 * 24 * 1000 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

        },
        getExpirationDateMin(_contract) {
            //.deployedDaysLength
            
            // let _currentEndDate_unix = _contract["creationTimestamp"] * 1000 + _contract["deployedDaysLength"] * 60 * 60 * 24 * 1000
            let _currentEndDate_unix = _contract["creationTimestamp"] * 1000 + (parseInt(_contract["deployedDaysLength"])+1) * 60 * 60 * 24 * 1000
            let _currentEndDate = new Date(_currentEndDate_unix).toISOString().split('T')[0]
            console.log("_currentEndDate",_currentEndDate)
            this.date_extend = _currentEndDate
            return _currentEndDate
        },
        getCurrentDate(_contract) {
              //.deployedDaysLength
              let _currentEndDate_unix = _contract["creationTimestamp"] * 1000 + _contract["deployedDaysLength"] * 60 * 60 * 24 * 1000
              let _currentEndDate = new Date(_currentEndDate_unix).toISOString().split('T')[0]
            return _currentEndDate
        },
        resetVars() {
            this.lastDirectorySelection = null;
            this.lastFileSelection = null;
            this.currentFile = undefined;
            this.currentFile_encr = undefined;
            this.currentDirectory = undefined;
            this.currentDirectory_encr = undefined;
            this.ethoProtocolKey = undefined;
            this.contractName = undefined;
            this.contractCallback = undefined;
            this.contractDuration = 100000;
            this.contractReplication = 10;
            this.accountName = undefined;
            this.inputs_readonly = false;
            this.useEncryption = false;
            this.fileUploadCost = 0;
        },
        checkJson(data) {
          try {
            return (JSON.parse(data) && !!data);
          } catch (e) {
            return false;
          }
        },
        resetFileLoad() {
          if (this.$refs.inputFile) {
            this.$refs.inputFile.reset();
          }
        },
        logout() {
            console.log("ADD SIWTCH ACCOUNT HERE!")
            this.loginSection = true;
            this.userSection = false;
            this.userSection = false;
            this.uploadSection = false;
            this.useEncryption = false;
            this.uploadDirectorySection = false;
            this.fileInfos = [];
            this.contractAddress = undefined;
            this.accountName = undefined;
            this.contractName = undefined;
            this.lastDirectorySelection = null;
            this.lastFileSelection = null;
            this.currentFile = undefined;
            this.currentFile_encr = undefined;
            this.currentDirectory = undefined;
            this.currentDirectory_encr = undefined;
            this.message = "";
        },
        login() {
          this.showProgress = false;
          // this.progress = 0;

            EthoFS.connect_metamask().then((metamask_is_connected) => {
                console.log("metamask_is_connected", metamask_is_connected)
                EthoFS.check_account().then((metamask_accounts) => {
                    console.log("metamask_accounts", metamask_accounts)
                    if (metamask_accounts == -1) {
                        console.log("SWITCH NOW")
                        console.log("SWITCH NOW")
                        this.message = "Please connect to metamask";


                    } else if (metamask_accounts.length >= 0) {
                      this.message = "login in account.";
                      EthoFS.login_account().then((metamask_accounts) => {
                        
                        if (metamask_accounts.length > 0) {
                          console.log("EMPTY")
                          this.message = "logged into account " + metamask_accounts[0];
                          this.message = "Retrieving existing contracts";
                          //UploadService.list(this.ethoProtocolKey).then(response => {
                          this.message = "";
                          //this.fileInfos = response.data;

                          EthoFS.get_user_etho().then((acc_value) => {
                            this.acc_value = acc_value
                            this.getEthoPrice().then((etho_price) => {
                                console.log("etho_price",etho_price)
                                if (etho_price !== -1) {
                                  console.log("etho_price 2",this.formatEtho(acc_value) * etho_price)
                                  // return "(" + (this.formatEtho(this.getAccountValue()) * etho_price) + " USD)"
                                  // this.acc_value_usd =  "(~" + this.formatUSD(acc_value * etho_price) + " $)"
                                  this.acc_value_usd =  this.formatUSD(acc_value * etho_price)
                                }
                              })
                          })

                          EthoFS.get_user_files(metamask_accounts[0]).then((file_ids) => {


                              console.log("files_ids:",file_ids)

                              //this.fileInfos = [{
                              //    address: "0x98598345",
                              //    data: "testdata",
                              //    ipfsHash: "testhash",
                              //    uploadBlock: 523523,
                              //    expirationBlock: 6436346
                              //}]
                              this.fileInfos = file_ids

                          })
                          //this.showUploadSection()
                          this.showViewDataSection()

                          //this.fileInfos = [{ "1": ["test", 123, 1245, 5235, 5235, 325] }]

                          //})
                      }
                    })
                  }
                })
            })


        },
        upload() {
          // this.progress = 0;
          if (!this.currentFile && !this.currentFile_encr) {
            this.message = this.MESSAGE_FILE;
            return;
          }

          if (!this.contractName) {
            this.message = this.MESSAGE_NAME;
            return;
          }
          if (!this.contractDuration) {
            this.message = this.MESSAGE_DURATION;
            return;
          }
          if (!this.contractReplication) {
            this.message = this.MESSAGE_REPFACTOR;
            return;
          }
          this.showProgress = true;
          this.message = "";

          // var self = this;

          // self.updateProgressBar("Waiting for user confirmation4...");

          // var progressInterval;
          // var loaded = 0;
          console.log("SENDING TO UPLOADS:",this.currentFile, this.contractName, this.contractDuration, this.contractReplication)

          let targetFile = this.currentFile_encr

          if(!targetFile || this.useEncryption == false) {
            console.log("UPLOADING NON-ENCRYPTED FILE")
            targetFile = this.currentFile
          } else {
            console.log("UPLOADING ENCRYPTED FILE")
          }

          console.log("this.currentFile_encr",this.currentFile_encr)
          console.log("this.currentFile",this.currentFile)
          console.log("targetFile",targetFile)

          EthoFS.uploadFiles(targetFile, this.contractName, this.contractDuration, this.contractReplication).then((response) => {
            console.log("-> accept/reject reponse: ", response);
            // this.showViewDataSection()

            console.log("this.fileInfos",this.fileInfos)
            EthoFS.get_contract(response.contract_id).then((_contract) => {
              console.log(_contract)
              this.fileInfos.push(_contract)
              this.showViewDataSection()
            })


            // var _this = this
            // setTimeout(function () { // why timeout ?
            //   _this.showViewDataSection()
            // }, 1000);

          })
          .catch((error) => {
            console.log(error)
            this.showProgress = false;
            // this.progress = 0;
            this.message = this.MESSAGE_UPLOADFAILED;
            
            var _this = this
            setTimeout(function () { // why timeout ?
              // _this.resetVars()
              // _this.resetFileLoad()
              // _this.showViewDataSection() 
              _this.showUploadSection(true) 
            }, 2000);

          });
          this.inputs_readonly = true;

        },
        uploadDirectory() {
          this.showProgress = false;
          // this.progress = 0;
          if (!this.currentDirectory && !this.currentDirectory_encr) {
            this.message = this.MESSAGE_DIRECTORY;
            return;
          }
          if (!this.contractName) {
            this.message = this.MESSAGE_NAME;
            return;
          }
          if (!this.contractDuration) {
            this.message = this.MESSAGE_DURATION;
            return;
          }
          if (!this.contractReplication) {
            this.message = this.MESSAGE_REPFACTOR;
            return;
          }

          

          this.showProgress = true;
          this.message = "";

          // var self = this;
          // var progressInterval;
          // var loaded = 0;

          let targetFile = this.currentDirectory_encr

          if(!targetFile || this.useEncryption == false) {
            console.log("UPLOADING NON-ENCRYPTED DIRECTORY")
            targetFile = this.currentDirectory
          } else {
            console.log("UPLOADING ENCRYPTED DIRECTORY")
          }

          // UploadService.uploadDirectory(this.currentDirectory, this.ethoProtocolKey, this.contractName, this.contractDuration, this.contractReplication, (event) => {
          EthoFS.uploadFiles(targetFile, this.contractName, this.contractDuration, this.contractReplication).then((response) => {
            console.log("-> accept/reject reponse: ", response);
            // this.showViewDataSection()

            console.log("this.fileInfos",this.fileInfos)
            EthoFS.get_contract(response.contract_id).then((_contract) => {
              console.log(_contract)
              this.fileInfos.push(_contract)
              this.showViewDataSection()
            })


            // var _this = this
            // setTimeout(function () { // why timeout ?
            //   _this.showViewDataSection()
            // }, 1000);

          })
          .catch((error) => {
            console.log(error)
            this.showProgress = false;
            // this.progress = 0;
            // this.message = "Could not upload the file";
            this.message = this.MESSAGE_UPLOADFAILED;
            
            var _this = this
            setTimeout(function () { // why timeout ?
              // _this.resetVars()
              // _this.showViewDataSection()
              _this.showUploadDirectorySection(true) 
            }, 2000);

          });
        },
        extend() {
          // this.progress = 0;
          if (!this.contractCallback) {
            this.message = this.MESSAGE_INVALIDCONTRACTSELECTED;
            return;
          }

          this.showProgress = true;
          this.message = "";
          //var loaded = 0;
            // var self = this;

            // self.updateProgressBar("Waiting for user confirmation1...");
            
  
            var _this = this;
            this.message = "Confirming contract extension";

            //UploadService.extend(this.ethoProtocolKey, this.contractCallback, this.contractDuration).then((response) => {
            EthoFS.extendContract(this.contractCallback["_index"], parseInt(this.contractCallback["contractStorageUsed"]), this.expandDays,parseInt(this.contractCallback["hostingReplicationFactor"])).then((response) => {
              console.log("-> accept/reject reponse: ", response);
              //this.message = response.data.message;
                //this.message = "Retrieving existing contracts";
                
                //loaded = 100;
          
                // this.updateProgressBar("extended!");
                //this.fileInfos = response.data;
                this.message = "Contract extension successful";
                
                setTimeout(function(){
                    _this.message = "";
                    _this.login()
                    _this.resetVars()
                    _this.showProgress = false;
                }, 1000);

   
              
            })
            .catch(() => {
                    // this.updateProgressBar("canceled!");

                  this.showProgress = false;
                  // this.progress = 0;
                  this.message = "Contract extension failed";
                  this.resetVars()
                  var _this = this
                  setTimeout(function () { // why timeout ?
                    _this.showViewDataSection()
                  }, 2000);
            });
        },
        remove() {
          // this.progress = 0;

          if (!this.contractCallback) {
            this.message = this.MESSAGE_INVALIDCONTRACTSELECTED;
            return;
            }

          var _this = this;
          this.showProgress = true;
          this.message = "";

          // var self = this;
          // self.updateProgressBar("Waiting for user confirmation2...");
          

          this.message = "Confirming contract deletion";
          //UploadService.remove(this.ethoProtocolKey, this.contractCallback).then((response) => {
          EthoFS.deleteContract(this.contractCallback["_index"]).then((response) => {
              console.log("-> accept/reject reponse: ", response);
            
              // this.updateProgressBar("deleted!");
              //this.message = response.data.message;
              this.message = "Contract deletion successful";


              setTimeout(function () { // why timeout ?
                  _this.message = "";
                  _this.login()
                  _this.resetVars()

                  _this.showProgress = false;
              }, 1000);



            })
            .catch(() => {
              this.showProgress = false;
              // this.progress = 0;
              this.message = "Contract deletion failed";
              this.resetVars()
              var _this = this
              setTimeout(function () { // why timeout ?
                _this.showViewDataSection()
              }, 2000);

            });
        },
        removeAll() {

          var _this = this;
          this.showProgress = true;
          this.message = "";

          // self.updateProgressBar("Waiting for user confirmation2...");

          this.message = "Confirming contract deletion";
          //UploadService.remove(this.ethoProtocolKey, this.contractCallback).then((response) => {
          // EthoFS.deleteContract(this.contractCallback["_index"]).then((response) => {
            EthoFS.deleteAllContracts().then((response) => {
              console.log("-> accept/reject reponse: ", response);
            
              // this.updateProgressBar("deleted!");
              //this.message = response.data.message;
              this.message = "Contract delete all contract successfull";

              setTimeout(function () { // why timeout ?
                _this.message = "";
                _this.login()
                _this.resetVars()

                _this.showProgress = false;
              }, 1000);

            })
            .catch(() => {
              this.showProgress = false;
              // this.progress = 0;
              this.message = "Contract delete all failed";
              this.resetVars()
              var _this = this
              setTimeout(function () { // why timeout ?
                _this.showViewDataSection()
              }, 2000);

            });
        },
        setowner() {

          console.log("NEW OWNER IS",document.getElementById('newowner').value)
          var newowner = document.getElementById('newowner').value
          if (EthoFS.utility.validate_wallet_address(newowner)) {
            this.newowner = EthoFS.utility.validate_wallet_address(newowner)
          } else {
            this.newowner = this.NEWOWNER_ERRORNAME
          }
          
        },
        changeowner() { // add field for newowner
          // this.progress = 0;

          if (!this.contractCallback) {
            this.message = this.MESSAGE_INVALIDCONTRACTSELECTED;
            return;
          }

          var _this = this;
          this.showProgress = true;
          this.message = "";

          // var self = this;
          // self.updateProgressBar("Waiting for user confirmation2...");
          

          this.message = "Confirming owner change";
          //UploadService.remove(this.ethoProtocolKey, this.contractCallback).then((response) => {
          EthoFS.changeOwnership(this.newowner,this.contractCallback["_index"]).then((response) => {
              console.log("-> accept/reject reponse: ", response);
            
              // this.updateProgressBar("deleted!");
              //this.message = response.data.message;
              this.message = "Contract owner change successful";


              setTimeout(function () {
                  _this.message = "";
                  _this.login()

                  _this.resetVars()
                  _this.showProgress = false;
              }, 1000);

            })
            .catch(() => {
              this.showProgress = false;
              // this.progress = 0;
              this.message = "Contract owner change failed";
              this.resetVars()
            });
        },
      },
      mounted() {
        window.ethereum.on('accountsChanged', async () => {
          console.log("SWITCHED ACCOUNTS",this)
          this.logout()
          this.login()
        });
        
      }
    };
    </script>
